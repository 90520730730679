

//////////////////////////////////////////////////////////////////////////////////////////////////////////////
///h : file:		host — static class — js 1.85
///h : version:		8.00.000000 — 2017.05.01__12:00.10
///h : credits:		plustic — massimo cardascia — www.plustic.de
///h : style:		whitesmiths style variant — 8 spaces tab — http://en.wikipedia.org/wiki/indent_style
//////////////////////////////////////////////////////////////////////////////////////////////////////////////
     
  
//////////////////////////////////////////////////////////////////////////////////////////////////////////////
///h : config
//////////////////////////////////////////////////////////////////////////////////////////////////////////////
 

//////////////////////////////////////////////////////////////////////////////////////////////////////////////
///h : imports
//////////////////////////////////////////////////////////////////////////////////////////////////////////////


//////////////////////////////////////////////////////////////////////////////////////////////////////////////
///h : class definition
//////////////////////////////////////////////////////////////////////////////////////////////////////////////

///h : class
function host(){}

	//////////////////////////////////////////////////////////////////////////////////////////////////////
	///h : class properties
	//////////////////////////////////////////////////////////////////////////////////////////////////////

	///h :
	host.__o_data = null;				///h : stores class data


	//////////////////////////////////////////////////////////////////////////////////////////////////////
	///h : static class init
	//////////////////////////////////////////////////////////////////////////////////////////////////////

	//////////////////////////////////////////////////////////////////////////////////////////////////////
	///h : class system - static init content
	/**
	 * public static function — f_initclass
	 * is called when a new object of the class
	 * is dynamicly created.
	 *
	 * @access public
	 * @return void  
	 *
	*/
	host.f_initClass = function()
		{
		///h :
		host.f_initConfig();
  
		///h :
		host.f_initContent();
		}
 

	//////////////////////////////////////////////////////////////////////////////////////////////////////
	///h : class system — static init data
	/**
	 * public static function — f_initconfig
	 * all content related data is initiated
	 * on class init directly.
	 *
	 * @access public
	 * @return void
	 * 
	 */
	host.f_initConfig = function($o_config)
		{
		///h :
		$o_config = dc7__var.f_initVar($o_config, {});

		///h :
		if(host.__o_data === null)
			{
			///h :
			host.__o_data = {};

			///h :
			host.__o_data['class__v_update'] = '8.00.000000'; 
			host.__o_data['class__tsc_update'] = '2017.05.01__12:00.10'; 

			///h :
			host.__o_data['b_tiny_mce'] = false;
			host.__o_data['tween_resize'] = null;
			host.__o_data['jqo_content'] = jQuery('.content');
			host.__o_data['jqo_image_reveal'] = jQuery('.om3__segment__image._reveal__true');
			host.__o_data['jqo_newslist_links'] = jQuery('.__s_segment_id__newslistlinks');
			host.__o_data['jqo_newslist_links_elements'] = host.__o_data['jqo_newslist_links'].find('.om3__segment__quicklink');
			host.__o_data['i_newslist_links_elements'] = host.__o_data['jqo_newslist_links_elements'].length;
			host.__o_data['jqo_newslist_news'] = jQuery('.om3__segment__newslist');
			host.__o_data['jqo_newslist_news_elements'] = host.__o_data['jqo_newslist_news'].find('.tile');
			host.__o_data['i_newslist_news_elements'] = host.__o_data['jqo_newslist_news_elements'].length;
			host.__o_data['jqo_links'] = host.__o_data['jqo_content'].find('a');
			host.__o_data['i_links'] = host.__o_data['jqo_links'].length;
			host.__o_data['num_window_y'] = 0;
			host.__o_data['jqo_quicklinks'] = jQuery('.om3__segment__quicklink');
			host.__o_data['i_quicklinks'] = host.__o_data['jqo_quicklinks'].length;
			host.__o_data['jqo_share'] = null;
			host.__o_data['b_shareactive'] = false;
			host.__o_data['b_overshare'] = false;
			}
		}

	//////////////////////////////////////////////////////////////////////////////////////////////////////
	///h : class system - static init content
	/**
	 * public static function — f_initcontent
	 * all content related functions are executed on
	 * class init directly.
	 *
	 * @access public
	 * @return void 
	 * 
	 */
	host.f_initParallax = function()
		{
		///h :
		var $o_data = null;			///h :
		var $jqo_elements = null;		///h :
		var $i_data = null;			///h :
		var $jqo_dom = null;			///h :
		var $i_id = null;			///h : 
		var $num_factor = null;			///h :
		var $s_id = null;			///h :
		var $i_elements = null;			///h :
		var $i_loop = null;			///h :

		///h :
		if(jQuery('.neos-backend').length === 1)
			{
			///h :
			return;
			} 
 
		///h :
		if(jQuery('._shadow').length > 0)
			{ 
			///h :
			$o_data = new Rellax('._shadow');
			}
		}


	//////////////////////////////////////////////////////////////////////////////////////////////////////
	///h : class system - static init content
	/**
	 * public static function — f_initcontent
	 * all content related functions are executed on
	 * class init directly.
	 *
	 * @access public
	 * @return void 
	 * 
	 */
	host.f_showContent = function()
		{
		///h : 
		if(window['b_load'] === true)
			{
			///h :
			host.f_showContent__fade__delayed(true);
			}
		///h : 
		else
			{
			///h :
			dc7.__o_data['dom__jqo_window'].bind('load', {}, function(){host.f_showContent__fade__delayed(true);});
			}

		///h :
		TweenMax.delayedCall(1.20, function(){dc7__scroller.f_updateURI(false);})
		}
		
		
	//////////////////////////////////////////////////////////////////////////////////////////////////////
	///h : class system - static init content
	/**
	 * public static function — f_initcontent
	 * all content related functions are executed on
	 * class init directly.
	 *
	 * @access public
	 * @return void 
	 * 
	 */
	host.f_showContent__fade__delayed = function($b_visible)
		{
		///h :
		TweenMax.delayedCall(1.20, function($uri_data){host.f_showContent__fade($b_visible);}, [$b_visible]);
		}
			


	//////////////////////////////////////////////////////////////////////////////////////////////////////
	///h : class system - static init content
	/**
	 * public static function — f_initcontent
	 * all content related functions are executed on
	 * class init directly.
	 *
	 * @access public
	 * @return void 
	 * 
	 */
	host.f_showContent__fade = function($b_visible)
		{
		///h :
		dc7__dom.f_setClass(dc7.__o_data['dom__jqo_html'], '_load__true', $b_visible);

		///h :
		dc7__events.f_updateView();

		///h :
		if(window.self === window.top)
			{
			///h :
			jQuery('html > body > .cc-window').addClass('_active__true');
			}
		}

	//////////////////////////////////////////////////////////////////////////////////////////////////////
	///h : class system - static init content
	/**
	 * public static function — f_initcontent
	 * all content related functions are executed on
	 * class init directly.
	 *
	 * @access public
	 * @return void 
	 * 
	 */
	host.f_initContent = function()
		{ 
		///h : 
		host.f_initResize();

		///h :
		host.f_initGrid();

		///h : parallax
		host.f_initParallax();

		///h : fx
		host.f_initFX();

		///h :
		host.f_initNewslist();

		///h :
		host.f_initBackend();

		///h :
		host.f_initHeaderImages();
		
		///h :
		host.f_initLazyload();

		///h :
		host.f_initProductDetail();

		///h :
		host.f_initScrolling(); 

		///h :
		host.f_initQuickLinks();
		
		///h :
		host.f_initProxy();
		
		///h :
		host.f_initShare();
		
		///h :
		host.f_updateForm();
		
		///h :
		host.f_initCookieWarning();
		
		///h :
		host.f_initForm();

		///h :
		host.f_showContent();
		}
		
		
	//////////////////////////////////////////////////////////////////////////////////////////////////////////////
	///h : breakpoints - init system
	/**
	 * init content
	 * initializing content and potential html rendering all requests
	 * to other uris.
	 *
	 * @access public
	 * @param
	 * @return vault
	 *
	 */
	host.f_initForm = function($b_active)
		{
		///h :
		var $jqo_dom = null;		///h :
		var $o_config = null;		///h :

		///h :
		$jqo_dom = jQuery('.help-inline');
		if($jqo_dom.length >= 1)
			{
			///h :
			$jqo_dom = $jqo_dom.first();
			
			///h :
			$o_config = {};
			$o_config['jqo_target'] = $jqo_dom.parent().parent();

			///h :
			dc7__scroller.f_scroll($o_config);
			}
		}
		
//////////////////////////////////////////////////////////////////////////////////////////////////////////////
	///h : breakpoints - init system
	/**
	 * init content
	 * initializing content and potential html rendering all requests
	 * to other uris.
	 *
	 * @access public
	 * @param
	 * @return vault
	 *
	 */
	host.f_initCookieWarning = function($b_active)
		{
		///h : only in frontend
		if(om3__frontend.__o_data['admin__b_available'] === false)
			{
			///h :
			$o_config = null;		///h : 

			///h :
			$o_config = {};
			$o_config['content'] = {};
			$o_config['content']['header'] = 'Cookies used on the website!';
			$o_config['content']['message'] = 'Unsere Website verwendet Cookies. Wenn Sie diese weiter nutzen, erklären Sie sich einverstanden.&nbsp;&nbsp;';
			$o_config['content']['dismiss'] = 'Ok, einverstanden';
			$o_config['content']['allow'] = '';
			$o_config['content']['deny'] = 'Nein, nicht einverstanden';
			$o_config['content']['link'] = 'Weitere Informationen.';
			$o_config['content']['href'] = '/de/datenschutz-disclaimer';
			$o_config['content']['close'] = '&#x274c;';
			$o_config['content']['theme'] = 'dark-top';
			$o_config['palette'] = {};
			$o_config['palette']['popup'] = {'background':'#000000'};
			$o_config['palette']['button'] = {'background':'#000000'};
 
			///h :
			window.cookieconsent.initialise($o_config);
			}
		}


	//////////////////////////////////////////////////////////////////////////////////////////////////////
	///h : init events
	/**
	 * public static function — f_initevents
	 */
	host.f_selectShareURI = function()
		{
		///h :
		var $uri_current = null;		///h : 
		var $jqo_dom = null;			///h :

		///h :
		$uri_current = location.href;
		$jqo_dom = jQuery('<input id=\'_clipboardhelper\' contenteditable=\'true\' tabindex=\'-1\' aria-hidden=\'true\' style=\'position:absolute; top:-99999px\'>');
		jQuery('body').append($jqo_dom);
		$jqo_dom.val($uri_current).select();
		
		range = document.createRange();
		range.selectNodeContents($jqo_dom[0]);
		selection = window.getSelection(); 
		selection.removeAllRanges();
		selection.addRange(range);
		$jqo_dom[0].setSelectionRange(0, 999999);

		///h :
		document.execCommand('copy');
 
		///h : 
		$jqo_dom.remove();

		///h :
		TweenMax.fromTo(host.__o_data['jqo_share__uri'], 2.80, {'backgroundColor':'#FAEBD7'}, {'backgroundColor':'#F2F2F2', 'ease':'Quad.easeOut', 'overwrite':'auto', 'delay':0.00});
		}
		
		
	//////////////////////////////////////////////////////////////////////////////////////////////////////
	///h : init events
	/**
	 * public static function — f_initevents
	 */
	host.f_activateShare = function($b_active)
		{
		///h :
		if($b_active === undefined)
			{
			///h :
			$b_active = !host.__o_data['b_shareactive'];
			}

		///h :
		if($b_active === host.__o_data['b_shareactive'])
			{
			///h :
			return;
			}
		host.__o_data['b_shareactive'] = $b_active;

		///h :
		$jqo_dom = jQuery('._item__share');
		dc7__dom.f_setClassVar($jqo_dom, '_active', host.__o_data['b_shareactive']);
		}


	//////////////////////////////////////////////////////////////////////////////////////////////////////
	///h : init events
	/**
	 * public static function — f_initevents
	 */
	host.f_initShare = function()
		{
		///h : 
		var $jqo_dom = null;		///h :
		var $html_data = null;		///h :

		///h : only in frontend
		if(om3__frontend.__o_data['admin__b_available'] === true)
			{
			///h :
			return;
			}

		///h :
		$jqo_dom = jQuery('body #content .content_page');

		///h : 
		$html_data = '';
		$html_data += ('<div class=\'_item__share __layout__icon _active__false\'>');
		
		$html_data += ('<div class=\'_item__share__button\'>');
		$html_data += ('<img class=\'_item__share__button__img\' src=\'https://www.belsana-apotheken.de/_Resources/Static/Packages/permanent.belsana/BMP/icons/share_icon.png\' alt=\' \' />');
		$html_data += ('</div>'); 
		$html_data += ('<div class=\'_item__share__content\'>');
		
		$html_data += ('<p class=\'_item__share__content__text\'>Aktuellen Link in die Zwischenablage kopieren:</p>');
		$html_data += ('<p class=\'_item__share__content__uri\'>' + location.href + '</p>');
		$html_data += ('<p class=\'_item__share__content__copy\'>ok</p>'); 

		$html_data += ('</div>');
		$html_data += ('</div>');
  
		///h :
		$jqo_dom.append($html_data);
		host.__o_data['jqo_share'] = jQuery('._item__share');
		host.__o_data['jqo_share__button'] = jQuery('._item__share__button');
		host.__o_data['jqo_share__uri'] = jQuery('._item__share__content__uri');
		host.__o_data['jqo_share__copy'] = jQuery('._item__share__content__copy');

		///h :
		host.__o_data['jqo_share__button'].on('click', {}, function(){host.f_activateShare();});
		host.__o_data['jqo_share__copy'].on('click', {}, function(){host.f_selectShareURI();});
		jQuery(window).on('scroll resize', {}, function(){host.f_activateShare(false);});
		host.__o_data['jqo_share'].on('mouseenter', {}, function(){host.__o_data['b_overshare'] = true});
		host.__o_data['jqo_share'].on('mouseleave', {}, function(){host.__o_data['b_overshare'] = false});
 
		///h :
		dc7.__o_data['dom__jqo_document'].on('click', {}, function(){host.f_checkCloseShare();});
		}
//		
		
	//////////////////////////////////////////////////////////////////////////////////////////////////////
	///h : init events
	/**
	 * public static function — f_initevents
	 */
	host.f_checkCloseShare = function()
		{
		///h :
		if(host.__o_data['b_shareactive'] === true)
			{
			///h :
			if(host.__o_data['b_overshare'] === false)
				{
				///h :
				host.f_activateShare(false);
				}
			}
		}

	
	//////////////////////////////////////////////////////////////////////////////////////////////////////
	///h : init events
	/**
	 * public static function — f_initevents
	 */
	host.f_updateFormUploadElement = function()
		{
		
		}
		
		
	//////////////////////////////////////////////////////////////////////////////////////////////////////
	///h : init events
	/**
	 * public static function — f_initevents
	 */
	host.f_updateForm = function()
		{
		///h :
		var $jqo_elements = null;	///h :
		var $i_loop = null;		///h :
		var $i_elements = null;		///h :
		var $jqo_dom = null;		///h :
		var $jqo_button = null;		///h :

		///h : 
		jQuery('form').addClass('om3__segment__nform');

		///h : 
		$jqo_elements = jQuery('input[type=\'file\']');
		$i_elements = $jqo_elements.length;

		///h :
		for($i_loop = 0; $i_loop < $i_elements; $i_loop++)
			{
			///h :
			$jqo_dom = jQuery($jqo_elements[$i_loop]);
			$jqo_dom.css({'display':'none'});

			///h :
			$s_id = dc7__string.f_getUniqueID();
			$jqo_dom.before('<button id=\'' + $s_id + '\'>Upload</button>');
			$jqo_button = jQuery('#'+ $s_id);
 
			///h :
			$jqo_button.on('click', {'jqo_dom':$jqo_dom}, function($o_event){$o_event.data['jqo_dom'].trigger('click');});
			}
		} 
 

	//////////////////////////////////////////////////////////////////////////////////////////////////////
	///h : init events
	/**
	 * public static function — f_initevents
	 */
	host.f_initProxy = function()
		{
		///h :
		var $o_config = null;			///h :
		var $jqo_dom = null;			///h :
		var $i_loop = null;			///h :
		var $i_elements = null;			///h :
		var $jqo_dom__current = null;		///h :
		var $jqo_dom__proxy = null;		///h :

		///h :
		if(jQuery('.neos-backend').length === 1)
			{
			///h :
			return;
			}

		///h : om3__segment__button 
		$jqo_dom = jQuery('._proxy');
		$i_elements = $jqo_dom.length;
		for($i_loop = 0; $i_loop < $i_elements; $i_loop++)
			{
			///h :
			$jqo_dom__current = jQuery($jqo_dom[$i_loop]);

			///h :
			$jqo_dom__proxy = $jqo_dom__current.find('.om3__segment__button, a img');

			///h : 
			dc7__events.f_proxy($jqo_dom__proxy, 'mouseenter mouseleave click');
			}
		} 

 
	//////////////////////////////////////////////////////////////////////////////////////////////////////
	///h : class system - static init content
	/**
	 * public static function — f_initcontent
	 * all content related functions are executed on
	 * class init directly.
	 *
	 * @access public
	 * @return void 
	 * 
	 */
	host.f_openInternLink__complete = function($uri_data)
		{
		///h : 
		location.href = $uri_data;
		}


	//////////////////////////////////////////////////////////////////////////////////////////////////////
	///h : class system - static init content
	/**
	 * public static function — f_initcontent
	 * all content related functions are executed on
	 * class init directly.
	 *
	 * @access public
	 * @return void 
	 * 
	 */
	host.f_openInternLink = function($uri_data)
		{
		///h : 
	//	host.f_showContent__fade(false);
	
		host.f_openInternLink__complete($uri_data);

		///h :
	//	TweenMax.delayedCall(0.01, function($uri_data){host.f_openInternLink__complete($uri_data);}, [$uri_data]);
		}


	//////////////////////////////////////////////////////////////////////////////////////////////////////
	///h : class system - static init content
	/**
	 * public static function — f_initcontent
	 * all content related functions are executed on
	 * class init directly.
	 *
	 * @access public
	 * @return void 
	 * 
	 */
	host.f_initQuickLinks = function()
		{
		///h :
		var $jqo_dom = null;		///h :
		var $i_loop = null;		///h :
		var jqo_dom__button = null;	///h :
 
		///h : 
		for($i_loop = 0; $i_loop < host.__o_data['i_quicklinks']; $i_loop++)
			{
			///h :
			$jqo_dom = jQuery(host.__o_data['jqo_quicklinks'][$i_loop]);
			$jqo_dom__button = $jqo_dom.find('.om3__segment__button_button');

			///h :
			$jqo_dom.bind('mouseenter', {'jqo_dom__button':$jqo_dom__button}, function($o_event){$o_event.data['jqo_dom__button'].addClass('_hover__true');});
			$jqo_dom.bind('mouseleave', {'jqo_dom__button':$jqo_dom__button}, function($o_event){$o_event.data['jqo_dom__button'].removeClass('_hover__true');});

			///h :
			$jqo_dom.addClass('_fx__zoom');
			}
		}


	//////////////////////////////////////////////////////////////////////////////////////////////////////
	///h : class system - static init content
	/**
	 * public static function — f_initcontent
	 * all content related functions are executed on
	 * class init directly.
	 *
	 * @access public
	 * @return void 
	 * 
	 */
	host.f_initProductDetail = function()
		{
		///h :
		var $jqo_elements = null;		///h :
		var $jqo_element = null;		///h :
		var $jqo_left = null;			///h :
		var $jqo_right = null;			///h :
		var $o_config = null;			///h :

		///h :
		if(jQuery('.neos-backend').length === 1)
			{
			///h :
			return;
			}

		///h :
		$jqo_element = jQuery('.host__productdetail');
		$jqo_element.addClass('_active__true');

		///h :
		if($jqo_element.length > 0)
			{
			///h :
			host.f_initProductDetail__left();

			///h :
			host.f_initProductDetail__right();

			///h : duplicate
			$jqo_elements = jQuery('.host__productdetail').parent().find('> .om3__segment__clip');
			$jqo_element = jQuery($jqo_elements[1]).find('> .neos-contentcollection > .om3__segment__clip').first();
			$jqo_element.addClass('_original__true');
 
			///h :
			$jqo_clone = jQuery($jqo_element[0].outerHTML);
			$jqo_clone.addClass('_clone__true');
			$jqo_clone.removeClass('_original__true');

			///h :
			$jqo_elements = $jqo_clone.find('img');
			$i_elements = $jqo_elements.length;

			///h :
			for($i_loop = 0; $i_loop < $i_elements; $i_loop++)
				{
				///h :
				$jqo_dom = jQuery($jqo_elements[$i_loop]);
				$jqo_dom.attr('src', $jqo_dom.data('original'));
				}

			///h : 
			$jqo_element = jQuery('.host__productdetail > .neos-contentcollection');
			$jqo_element.prepend($jqo_clone);
			}	
		}


	//////////////////////////////////////////////////////////////////////////////////////////////////////
	///h : class system - static init content
	/**
	 * public static function — f_initcontent
	 * all content related functions are executed on
	 * class init directly.
	 *
	 * @access public
	 * @return void 
	 * 
	 */
	host.f_initProductDetail__left = function()
		{
		///h :
		var $jqo_base = null;			///h :
		var $jqo_elments = null;		///h :
		var $i_elements = null;			///h :
		var $jqo_dom = null;			///h :
		var $o_tip = null;			///h :
		var $o_config = null;			///h :
		var $jqo_img = null;			///h :
		var $s_title = null;			///h :

		///h :
		$jqo_base = jQuery(jQuery('.host__productdetail > .neos-contentcollection > .om3__segment__clip')[0]);
		$jqo_base.addClass('host__productdetail__left');

		///h :
		$jqo_elements = $jqo_base.find('> .neos-contentcollection > .om3__segment__clip > .neos-contentcollection .om3__segment');
		$i_elements = $jqo_elements.length;

		///h :
		for($i_loop = 0; $i_loop < $i_elements; $i_loop++)
			{
			///h :
			$jqo_dom = jQuery($jqo_elements[$i_loop]);
			$jqo_img = $jqo_dom.find('img');
			$s_title = $jqo_img.attr('title');
			$jqo_img.removeAttr('title');

			///h :
			$jqo_dom.attr('title', $s_title);
			$jqo_dom.addClass('_tooltip__left _productdetailbox__left'); 
			}
			
		///h :
		$o_config = {};
		$o_config['animation'] = 'fade';
		$o_config['trigger'] = 'hover';
		$o_config['arrow'] = true;
		$o_config['interactive'] = false;
		$o_config['distance'] = -30;
		$o_config['side'] = 'right';
		$o_config['trackTooltip'] = true;
		$o_config['animationDuration'] = 150;
		$o_config['contentAsHTML'] = true;

		///h :
		jQuery('._tooltip__left').tooltipster($o_config);
		}
		
		
	//////////////////////////////////////////////////////////////////////////////////////////////////////
	///h : class system - static init content
	/**
	 * public static function — f_initcontent
	 * all content related functions are executed on
	 * class init directly.
	 *
	 * @access public
	 * @return void 
	 * 
	 */
	host.f_initProductDetail__right = function()
		{
		///h :
		var $jqo_base = null;			///h :
		var $jqo_elments = null;		///h :
		var $i_elements = null;			///h :
		var $jqo_dom = null;			///h :
		var $o_tip = null;			///h :
		var $o_config = null;			///h :
		var $jqo_text = null;			///h :

		///h :
		$jqo_base = jQuery(jQuery('.host__productdetail > .neos-contentcollection > .om3__segment__clip')[1]);
		$jqo_base.addClass('host__productdetail__right');

		///h :
		$jqo_elements = $jqo_base.find('> .neos-contentcollection > .om3__segment__clip');
		$i_elements = $jqo_elements.length;

		///h :
		for($i_loop = 0; $i_loop < $i_elements; $i_loop++)
			{
			///h :
			$jqo_dom = jQuery($jqo_elements[$i_loop]);
			$jqo_text = $jqo_dom.find('.om3__segment__text')

			///h :
			$jqo_dom.attr('title', $jqo_text.text()); 
			$jqo_text.css({'display':'none'});
			$jqo_dom.addClass('_tooltip__right _productdetailbox__right'); 
			}
			
		///h :
		$o_config = {};
		$o_config['animation'] = 'fade';
		$o_config['trigger'] = 'hover';
		$o_config['arrow'] = true;
		$o_config['interactive'] = false;
		$o_config['distance'] = -30;
		$o_config['side'] = 'left';
		$o_config['trackTooltip'] = true;
		$o_config['animationDuration'] = 150;
		$o_config['contentAsHTML'] = true;

		///h :
		jQuery('._tooltip__right').tooltipster($o_config);
		}
		
		
	//////////////////////////////////////////////////////////////////////////////////////////////////////
	///h : class system - static init content
	/**
	 * public static function — f_initcontent
	 * all content related functions are executed on
	 * class init directly.
	 *
	 * @access public
	 * @return void 
	 * 
	 */
	host.f_initLazyload__complete = function($jqo_dom)
		{
		///h :
		var $o_fx = null;			///h :
		var $s_id = null;			///h :
		var $jqo_dom__parent = null;		///h :
		var $s_class = null;			///h :
		var $jqo_dom__shadow = null;		/// h:

		///h :
		jQuery(window).trigger('resize');

		///h :
		$jqo_dom__parent = $jqo_dom.parent().parent().parent();
		$jqo_dom__shadow = $jqo_dom__parent.find('._shadow');
		if($jqo_dom__shadow.length === 1)
			{
			///h :
			$s_id = dc7__dom.f_getID($jqo_dom__shadow);
			$o_fx = new Rellax('#' + $s_id);
			}
		}

		
	//////////////////////////////////////////////////////////////////////////////////////////////////////
	///h : class system - static init content
	/**
	 * public static function — f_initcontent
	 * all content related functions are executed on
	 * class init directly.
	 *
	 * @access public
	 * @return void 
	 * 
	 */
	host.f_initLazyload = function()
		{
		///h :
		var $o_config = null;		///h :
		
		///h 
		$o_config = {};
		$o_config['class_loaded'] = '_loaded__true';
		$o_config['class_initial'] = '_loaded__false';
		$o_config['threshold'] = 240;
		$o_config['elements_selector'] = '._lazy';
		$o_config['callback_load'] = function($dom_data){host.f_initLazyload__complete(jQuery($dom_data));};
 
		///h :
		host.__o_data['o_lazy_load'] = new LazyLoad($o_config);
		}

		 
	//////////////////////////////////////////////////////////////////////////////////////////////////////
	///h : class system - static init content
	/**
	 * public static function — f_initcontent
	 * all content related functions are executed on
	 * class init directly.
	 *
	 * @access public
	 * @return void 
	 * 
	 */
	host.f_initHeaderImages = function()
		{ 
		///h :
		var $jqo_dom = null;		///h :
		var $jqo_content = null;	///h : 
		var $jqo_dom__current = null;	///h :
		var $jqo_dom__button = null;	///h :

		///h :
		$jqo_dom = jQuery('.om3__segment__clip.__b_header_image');

		///h : 
		if($jqo_dom.length === 1)
			{
			///h :
			$jqo_content = $jqo_dom.find('> .neos-contentcollection > *');
			$html_content = ($jqo_content[0].outerHTML);
			$jqo_dom__current = jQuery($html_content);
			
			///h :
			$jqo_dom__button = $jqo_dom__current.find('.om3__segment__button');
			dc7__dom.f_setClassVar($jqo_dom__button, '__s_type', 'a');

			///h :
			$jqo_dom__current.addClass('__b_header_image__prepend');
			$jqo_dom__current.removeClass('neos-contentcollection');

			

			///h : 
			$jqo_dom.parent().prepend($jqo_dom__current);
			}
		}


	//////////////////////////////////////////////////////////////////////////////////////////////////////
	///h : class system - static init content
	/**
	 * public static function — f_initcontent
	 * all content related functions are executed on
	 * class init directly.
	 *
	 * @access public
	 * @return void 
	 * 
	 */
	host.f_updateScroll = function()
		{
		///h :
		var $num_y__current = null;		///h :
		var $num_y__target = null;		///h : 

		///h :
		if(host.__o_data['b_update'] !== true)
			{
			///h :
			host.__o_data['b_update'] = true;

			///h :
			$num_y__current = (window.scrollY);
			host.__o_data['num_window_y'] += (($num_y__current - host.__o_data['num_window_y']) / 8);

			///h : 
			TweenMax.set(jQuery('#content_page'), {'y':($num_y__current - host.__o_data['num_window_y'])});
			}

		///h :
		host.__o_data['b_update'] = false;
		}


	//////////////////////////////////////////////////////////////////////////////////////////////////////
	///h : class system - static init content
	/**
	 * public static function — f_initcontent
	 * all content related functions are executed on
	 * class init directly.
	 *
	 * @access public
	 * @return void 
	 * 
	 */
	host.f_checkModalScrolling = function($o_event)
		{
		///h :
		if(dc7__modal.__o_data['b_open'] === true)
			{
		//	$o_event.preventDefault();

			///h :
		//	return(false); 
			}
		}


	//////////////////////////////////////////////////////////////////////////////////////////////////////
	///h : class system - static init content
	/**
	 * public static function — f_initcontent
	 * all content related functions are executed on
	 * class init directly.
	 *
	 * @access public
	 * @return void 
	 * 
	 */
	host.f_initScrolling = function()
		{
		///h : 
		var $jqo_dom = null;		///h :
		var $s_name = null;		///h :

		///h :
		if(jQuery('.neos-backend').length === 1)
			{
			///h :
			return;
			} 

		///h : 
		history.scrollRestoration = 'manual';
 
		///h :
	//	jQuery(document).on('touchmove', function($o_event){host.f_checkModalScrolling($o_event);host.f_checkModalScrolling($o_event);});
		
		
	//	document.ontouchmove = function($o_event){}
		}


	//////////////////////////////////////////////////////////////////////////////////////////////////////
	///h : class system - static init content
	/**
	 * public static function — f_initcontent
	 * all content related functions are executed on
	 * class init directly.
	 *
	 * @access public
	 * @return void 
	 * 
	 */
	host.f_initBackend = function()
		{
		///h :
		if(jQuery('.neos-backend').length === 1)
			{
			///h :
			jQuery(document).bind('click', {}, function(){host.f_updateBackend();});
			}
		}
		
		
	//////////////////////////////////////////////////////////////////////////////////////////////////////
	///h : class system - static init content
	/**
	 * public static function — f_initcontent
	 * all content related functions are executed on
	 * class init directly.
	 *
	 * @access public
	 * @return void 
	 * 
	 */
	host.f_updateBackend = function()
		{
		///h :
		var $jqo_dom = null;		///h :
		var $jqo_section = null;	///h :
		var $i_section = null;		///h :
		var $i_loop = null;		///h :
		var $i_data = null;		///h :
 
		///h :
 
		///h :
		//$jqo_section = jQuery('#neos-inspector > .neos-inspector-form > .neos-inspector-tab-content');
		//$i_section = $jqo_section.length;
//		
//		$jqo_label = ('#neos-inspector > .neos-inspector-form > .neos-inspector-tab-content > .neos-inspector-section > .neos-inspector-field > .ember-view > label');
//		$i_label = $jqo_label.length;
//		for($i_loop = 0; $i_loop < $i_label; $i_loop++)
//			{
//			///h :
//			$jqo_label__current = jQuery($jqo_label[$i_loop]);
//			//$jqo_label__current.css({'color':'red'});
//			}
		}
		
	//////////////////////////////////////////////////////////////////////////////////////////////////////
	///h : class system - static init content
	/**
	 * public static function — f_initcontent
	 * all content related functions are executed on
	 * class init directly.
	 * 
	 * @access public
	 * @return void 
	 * 
	 */
	host.f_initNewslist = function()
		{
		///h :
		var $jqo_dom = null;		///h :
		var $jqo_dom__news = null;	///h :
		var $o_config = null;		///h :
		
		///h :
		host.__o_data['jqo_newslist_news_elements'].addClass('_packery');
		host.__o_data['jqo_newslist_news_elements'].unwrap();

		///h :
		if(((host.__o_data['jqo_newslist_links'].length > 0) && (host.__o_data['jqo_newslist_news'].length > 0)))
			{
			///h
			for($i_loop = 0; $i_loop < host.__o_data['i_newslist_links_elements']; $i_loop++)
				{
				///h :
				$jqo_dom = jQuery(host.__o_data['jqo_newslist_links_elements'][$i_loop]);
				$jqo_dom.addClass('_packery _packery_quicklink');

				///h :
				$jqo_dom__news = jQuery(host.__o_data['jqo_newslist_news_elements'][($i_loop * 3) + 1]);
  
				///h :
				$jqo_dom.insertAfter($jqo_dom__news);  
				}
			}
 
		///h :
		$o_config = {};
		$o_config['itemSelector'] = '._packery';
		$o_config['transitionDuration'] = '0.001s';
		$o_config['gutter'] = 0;
		$o_config['percentPosition'] = true;
		$o_config['resize'] = true;
	//	$o_config['stamp'] = '._packery_stamp';

		///h :
		if(host.__o_data['jqo_newslist_news'].length > 0)
			{
			host.__o_data['jqo_newslist_news'].find('.om3__segment__newslist_elements').packery($o_config);
  
			///h :
			host.__o_data['jqo_newslist_news'].css({'display':'inline-block', 'width':'100%'});

			///h :
			jQuery(window).on('resize load', {}, function(){host.__o_data['jqo_newslist_news'].find('.om3__segment__newslist_elements').packery()});
			}
		}
		
	//////////////////////////////////////////////////////////////////////////////////////////////////////
	///h : class system - static init content
	/**
	 * public static function — f_initcontent
	 * all content related functions are executed on
	 * class init directly.
	 *
	 * @access public
	 * @return void 
	 * 
	 */
	host.f_initMasonry = function()
		{ 
		///h :  
		var $o_config = null;

//		///h :
//		$o_config = {};
//		$o_config['itemSelector'] = '.tile';
//		$o_config['columnWidth'] = 1;
//		$o_config['upperPosition'] = 240;
//		$o_config['shelfOrder'] = 240;
//		$o_config['layoutPriorities'] = {'upperPosition':1, 'shelfOrder':1};
//  
//		///h : 
//		if(jQuery('.om3__segment__newslist').length > 0)
//			{
//			///h :
//			jQuery('.om3__segment__newslist').masonry($o_config);
//			}

		} 

 
	//////////////////////////////////////////////////////////////////////////////////////////////////////
	///h : class system - static init content
	/**
	 * public static function — f_initcontent
	 * all content related functions are executed on
	 * class init directly.
	 *
	 * @access public
	 * @return void 
	 * 
	 */
	host.f_initFX = function()
		{ 
		///h :
		var $o_config = null;		///h :
		var $o_fx = null;		///h :


		//////////////////////////////////////////////////////////////////////////////////////////////
		///h : double events

		///h :
		
		

		//////////////////////////////////////////////////////////////////////////////////////////////
		///h : shadow

		///h : 
		$o_config = {};
		$o_config['o_events'] = {};
		$o_config['o_events']['dc7__behavior__scrollreveal__first__in__sequencer'] = {'s_call':'play', 'num_timescale':1.20};
 
		///h :
		if(jQuery('.neos-backend').length < 1)
			{
			///h :
			dc7__timeline.f_addTimeline(host.__o_data['jqo_image_reveal'], 'project__timeline__shadow', $o_config);
			} 

		///h :
		if(jQuery('.neos-backend').length < 1)
			{
			$o_fx = ScrollReveal({});
			$o_fx.reveal('._fx__pop', {'duration':1000, 'origin':'left', 'viewFactor': 0.30, 'reset':false, 'distance':'140px', 'delay':0.01}, 10);
			} 
		}


	//////////////////////////////////////////////////////////////////////////////////////////////////////
	///h : class system - static init content
	/**
	 * public static function — f_initcontent
	 * all content related functions are executed on
	 * class init directly.
	 *
	 * @access public
	 * @return void 
	 * 
	 */
	host.f_initResize = function($o_event)
		{
		///h :
		dc7.__o_data['dom__jqo_window'].on('resize', {}, function(){host.f_checkResize();});
		host.f_checkResize__complete();
		}


	//////////////////////////////////////////////////////////////////////////////////////////////////////
	///h : class system - static init content
	/**
	 * public static function — f_initcontent
	 * all content related functions are executed on
	 * class init directly.
	 *
	 * @access public
	 * @return void 
	 * 
	 */
	host.f_checkResize__complete = function()
		{
		///h :
	//	host.__o_data['jqo_content'].removeClass('_noanimation');
		}


	//////////////////////////////////////////////////////////////////////////////////////////////////////
	///h : class system - static init content
	/**
	 * public static function — f_initcontent
	 * all content related functions are executed on
	 * class init directly.
	 *
	 * @access public
	 * @return void 
	 * 
	 */
	host.f_checkResize = function()
		{
		///h : 
		if(host.__o_data['tween_resize'] !== null)
			{
			///h :
			host.__o_data['tween_resize'].kill();
			host.__o_data['tween_resize'] = null;
			} 
 
		///h : 
	//	host.__o_data['jqo_content'].addClass('_no_animation');
 
		///h : 
		host.__o_data['tween_resize'] = TweenMax.to(dc7.__o_data['dom__jqo_window'], 0.40, {'onComplete':function(){host.f_checkResize__complete();}});
		}
		 
		
	//////////////////////////////////////////////////////////////////////////////////////////////////////
	///h : class system - static init content
	/**
	 * public static function — f_initcontent
	 * all content related functions are executed on
	 * class init directly.
	 *
	 * @access public
	 * @return void 
	 * 
	 */ 
	host.f_initGrid = function($o_event)
		{
		///h :
		host.__o_data['b_apple'] = ((navigator.platform.match(/(Mac|iPhone|iPod|iPad)/i)) ? true : false);
		dc7__dom.f_setClassVar(dc7.__o_data['dom__jqo_html'], '_apple', host.__o_data['b_apple']);

		///h : default config
		dc7__grid.f_resetBreakpoints();
		dc7__grid.f_addBreakpoint({'s_breakpoint_id':'sma', 'i_window_width_max':850, 'a_device_types':['smartphone_por', 'smartphone_lan', 'tablet_por'], 'i_fontsize':10});
		dc7__grid.f_addBreakpoint({'s_breakpoint_id':'tab', 'i_window_width_max':992, 'b_tablet':true, 'a_device_types':['tablet_lan'], 'i_fontsize':12});
		dc7__grid.f_addBreakpoint({'s_breakpoint_id':'des', 'i_window_width_min':1023, 'b_desktop':true, 'i_fontsize':16});
		dc7__grid.f_updateGrid();
		}


//////////////////////////////////////////////////////////////////////////////////////////////////////////////
///h : direct call
//////////////////////////////////////////////////////////////////////////////////////////////////////////////

///h :
jQuery(window).load(function(){window['b_load'] = true;});
jQuery(document).ready(function(){TweenMax.delayedCall(0.40, function(){host.f_initClass()})});

 
//////////////////////////////////////////////////////////////////////////////////////////////////////////////
///h : description
//////////////////////////////////////////////////////////////////////////////////////////////////////////////
/*
*/


//////////////////////////////////////////////////////////////////////////////////////////////////////////////
///h : log
//////////////////////////////////////////////////////////////////////////////////////////////////////////////
/*
*/
