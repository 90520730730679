/*
//////////////////////////////////////////////////////////////////////////////////////////////////////////////
code:		plustic — massimo cardascia — www.plustic.de
code language:	js 5
code style:	whitesmiths style variant - 8 spaces tab - http://en.wikipedia.org/wiki/indent_style
//////////////////////////////////////////////////////////////////////////////////////////////////////////////
*/

///*	class definition /////////////////////////////////////////////////////////////////////////////////////
function project__behavior__menu($o_config){this.f_construct($o_config);}
 
///*    class properties /////////////////////////////////////////////////////////////////////////////////////

///*    class system init ////////////////////////////////////////////////////////////////////////////////////
	project__behavior__menu.f_initSystem = function()
		{
		project__behavior__menu.f_init();
		}

///*    class constructor ////////////////////////////////////////////////////////////////////////////////////
	project__behavior__menu.prototype.f_construct = function($o_config)
		{
		///h : init
		this.f_init($o_config);
		}

///*    public static functions //////////////////////////////////////////////////////////////////////////////

///*    public functions /////////////////////////////////////////////////////////////////////////////////////

///*    pivate static functions //////////////////////////////////////////////////////////////////////////////

	//////////////////////////////////////////////////////////////////////////////////////////////////////
	///h : class static methods - default
	//////////////////////////////////////////////////////////////////////////////////////////////////////


	//////////////////////////////////////////////////////////////////////////////////////////////////////
	///h : init content
	/**
	 * init content
	 * initializing content and potential html rendering all requests
	 * to other uris.
	 *
	 * @access public
	 * @param
	 * @return vault
	 *
	 */
	project__behavior__menu.f_init = function()
		{
		///h :
		project__behavior__menu.f_initData();
		
		///h :
		project__behavior__menu.f_initContent();
		}

	//////////////////////////////////////////////////////////////////////////////////////////////////////
	///h : init data
	/**
	 * init content
	 * initializing content and potential html rendering all requests
	 * to other uris.
	 *
	 * @access public
	 * @param
	 * @return vault
	 *
	 */
	project__behavior__menu.f_initData = function()
		{
		///h :
		}


	//////////////////////////////////////////////////////////////////////////////////////////////////////
	///h : init data
	/**
	 * init content
	 * initializing content and potential html rendering all requests
	 * to other uris.
	 *
	 * @access public
	 * @param
	 * @return vault
	 *
	 */
	project__behavior__menu.f_initContent = function()
		{
		///h : init behavior
		dc7__behavior.f_initBehaviorClass({'s_behavior_id':'project__behavior__menu', 'class_behavior':project__behavior__menu, 'selector_behavior':'.om3__segment__menu'});
		dc7__behavior.f_updateElements();
		}


	//////////////////////////////////////////////////////////////////////////////////////////////////////////////
	///h : breakpoints - init system
	/**
	 * init content
	 * initializing content and potential html rendering all requests
	 * to other uris.
	 *
	 * @access public
	 * @param
	 * @return vault
	 *
	 */
	project__behavior__menu.prototype.f_init = function($o_config)
		{
		///h : update important values
		this.f_initData($o_config);

		///h : init engine
		this.f_initContent();
		}


	//////////////////////////////////////////////////////////////////////////////////////////////////////////////
	///h : breakpoints - init system
	/**
	 * init content
	 * initializing content and potential html rendering all requests
	 * to other uris.
	 *
	 * @access public
	 * @param
	 * @return vault
	 *
	 */
	project__behavior__menu.prototype.f_initData = function($o_config)
		{
		///h : data 
		this.__o_data = {};
		this.__o_data['o_config'] = $o_config;
		this.__o_data['jqo_element'] = $o_config['jqo_element'];
		this.__o_data['b_topalign'] = null;
		this.__o_data['b_mobile_navigation'] = false;
		this.__o_data['jqo_burger'] = this.__o_data['jqo_element'].find('.om3__segment__menu_navigation_burger');
		this.__o_data['jqo_mobilemenu'] = this.__o_data['jqo_element'].find('.om3__segment__menu_mobilenavigation');
		this.__o_data['jqo_navigation_elements'] = this.__o_data['jqo_element'].find('.om3__segment__menu_navigation_elements');
		this.__o_data['jqo_elements'] = this.__o_data['jqo_element'].find('.om3__segment__menu_navigation_elements > div > a');
		this.__o_data['i_elements'] = this.__o_data['jqo_elements'].length;
		this.__o_data['jqo_line'] = this.__o_data['jqo_element'].find('.om3__segment__menu_navigation_elements_line');
		}

	//////////////////////////////////////////////////////////////////////////////////////////////////////////////
	///h : breakpoints - init system
	/**
	 * init content
	 * initializing content and potential html rendering all requests
	 * to other uris.
	 *
	 * @access public
	 * @param
	 * @return vault
	 *
	 */
	project__behavior__menu.prototype.f_showMobileNavigation = function($b_visible)
		{
		///h : 
		if($b_visible === undefined)
			{
			///h :
			$b_visible = !this.__o_data['b_mobile_navigation'];
			}

		///h :
		if(this.__o_data['b_mobile_navigation'] === $b_visible)
			{
			///h :
			return;
			}
		this.__o_data['b_mobile_navigation'] = $b_visible;

		///h :
		dc7__dom.f_setClassVar(this.__o_data['jqo_mobilemenu'], '_active', $b_visible);

		///h :	
		dc7__dom.f_setClassVar(dc7.__o_data['dom__jqo_html'], '_noscroll', $b_visible);

		///h :
		dc7.__o_data['dom__jqo_window'].trigger('resize');
		
		///h :
		dc7__dom.f_setClassVar(this.__o_data['jqo_burger'], '_active', $b_visible);
		}
		
	//////////////////////////////////////////////////////////////////////////////////////////////////////////////
	///h : breakpoints - init system
	/**
	 * init content
	 * initializing content and potential html rendering all requests
	 * to other uris.
	 *
	 * @access public
	 * @param
	 * @return vault
	 *
	 */
	project__behavior__menu.prototype.f_hilite = function($i_index, $b_animation)
		{
		///h :
		var $num_duration = null;		///h :
		var $i_x = null;			///h :
		var $i_width = null;			///h : 
			
		///h :
		$jqo_dom = (($i_index === null) ? this.__o_data['jqo_navigation_elements'].find('.current a') : jQuery(this.__o_data['jqo_elements'][$i_index]));

		///h :
		if($jqo_dom.length === 1)
			{
			///h :
			$i_x = $jqo_dom.position().left;
			$i_width = $jqo_dom.width();	
			}
		else
			{
			///h :
			$i_x = 0;
			$i_width = 0;	
			}
			
		$num_duration = (($b_animation === true) ? 0.40 : 0.01);
		
		///h : 
		TweenMax.to(this.__o_data['jqo_line'], $num_duration, {'x':$i_x, 'width':$i_width, 'ease':'Expo.easeOut', 'overwrite':'auto', 'delay':0.00});
		}


	//////////////////////////////////////////////////////////////////////////////////////////////////////////////
	///h : breakpoints - init system
	/**
	 * init content
	 * initializing content and potential html rendering all requests
	 * to other uris.
	 *
	 * @access public
	 * @param
	 * @return vault
	 *
	 */
	project__behavior__menu.prototype.f_initContent = function()
		{
		///h : 
		dc7.__o_data['dom__jqo_window'].on('dc7__grid__breakpointchange resize scroll load', {'o_base':this}, function($o_event){$o_event.data['o_base'].f_update();});
		this.f_update();

		///h : 
		dc7.__o_data['dom__jqo_window'].on('dc7__grid__breakpointchange', {'o_base':this}, function($o_event){$o_event.data['o_base'].f_showMobileNavigation(false);});
		this.__o_data['jqo_burger'].on('click', {'o_base':this}, function($o_event){$o_event.data['o_base'].f_showMobileNavigation();});
		dc7__dom.f_setClassVar(this.__o_data['jqo_burger'], '_active', false);

		///h :
		for($i_loop = 0; $i_loop < this.__o_data['i_elements']; $i_loop++)
			{
			///h :
			$jqo_dom = jQuery(this.__o_data['jqo_elements'][$i_loop]);
			$i_index = $i_loop;
			$jqo_dom.on('mouseenter', {'o_scope':this, 'i_index':$i_index}, function($o_event){$o_event.data['o_scope'].f_hilite($o_event.data['i_index'], true);});
			$jqo_dom.on('mouseout', {'o_scope':this, 'i_index':$i_index}, function($o_event){$o_event.data['o_scope'].f_hilite(null, true);});
			}
			
		dc7.__o_data['dom__jqo_window'].on('dc7__grid__breakpointchange resize scroll load', {'o_base':this}, function($o_event){$o_event.data['o_base'].f_hilite(null, false);});
		this.f_hilite(null, false);
		}


	//////////////////////////////////////////////////////////////////////////////////////////////////////////////
	///h : breakpoints - init system
	/**
	 * init content - f_update
	 * 
	 */
	project__behavior__menu.prototype.f_update = function()
		{
		///h :
		var $i_y = null;		///h :
		var $b_topalign = null;		///h :
		var $s_menucompact = null;	///h :

		///h :
		$i_y = (window.pageYOffset || document.documentElement.scrollTop);
		$b_topalign = (($i_y < 10) ? false : true);

		///h : special case
		if(dc7__grid.__o_data['s_breakpoint_id'] === 'sma')
			{
			///h :
			$b_topalign = true;
			}
			
		///h : 
		$s_menucompact = ((dc7.__o_data['dom__jqo_window'].width() < 1300) ? 2 : 0);
		$s_menucompact = (($b_topalign === true) ? 1 : $s_menucompact);
		dc7__dom.f_setClassVar(dc7.__o_data['dom__jqo_html'], '_menucompact', $s_menucompact);
  
		
		
		///h :
		if(this.__o_data['b_topalign'] === $b_topalign)
			{
			///h :
			return;
			}
		this.__o_data['b_topalign'] = $b_topalign;

		///h :
		dc7__dom.f_setClassVar(this.__o_data['jqo_element'], '_topalign', $b_topalign);
		}


///*    init class ///////////////////////////////////////////////////////////////////////////////////////////
jQuery(document).bind('ready', function(){project__behavior__menu.f_initSystem();});

/*
--------------------------------------------------------------------------------------------------------------
description:
--------------------------------------------------------------------------------------------------------------
--------------------------------------------------------------------------------------------------------------
*/