//////////////////////////////////////////////////////////////////////////////////////////////////////////////
///h : code:		plustic — massimo cardascia — www.plustic.de
///h : code language:	php 5.6
///h : code style:	whitesmiths style variant - 8 spaces tab - http://en.wikipedia.org/wiki/indent_style
//////////////////////////////////////////////////////////////////////////////////////////////////////////////


//////////////////////////////////////////////////////////////////////////////////////////////////////////////
///h : paths
//////////////////////////////////////////////////////////////////////////////////////////////////////////////


//////////////////////////////////////////////////////////////////////////////////////////////////////////////
///h : imports
//////////////////////////////////////////////////////////////////////////////////////////////////////////////


//////////////////////////////////////////////////////////////////////////////////////////////////////////////
///h : class definition
//////////////////////////////////////////////////////////////////////////////////////////////////////////////

///h : class
function dc7__behavior__packery($o_config){this.f_construct($o_config);}

	//////////////////////////////////////////////////////////////////////////////////////////////////////
	///h : class properites
	//////////////////////////////////////////////////////////////////////////////////////////////////////

	///h : class vars
	dc7__behavior__packery.__o_data = null;				///h : stores class data
	dc7__behavior__packery.__o_config = null;				///h : stores class config

	//////////////////////////////////////////////////////////////////////////////////////////////////////
	///h : class system init
	//////////////////////////////////////////////////////////////////////////////////////////////////////

	///h : init system
	/**
	 * init system
	 * initialising the system. all default mehtods to init data and content are
	 * called when the class is included.
	 * 
	 * @access public
	 * @param
	 * @return vault
	 */
	dc7__behavior__packery.f_initSystem = function()
		{
		dc7__behavior__packery.f_init();
		}
	

	/////////////////////////////////////////////////////////////////////////////////////////////////////
	///h : class construct
	//////////////////////////////////////////////////////////////////////////////////////////////////////

	///h : init system
	/**
	 * init system
	 * initialising the system. all default mehtods to init data and content are
	 * called when the class is included.
	 * 
	 * @access public
	 * @param
	 * @return vault
	 */
	dc7__behavior__packery.prototype.f_construct = function($o_config)
		{
		this.f_init($o_config);
		}


	//////////////////////////////////////////////////////////////////////////////////////////////////////
	///h : class static methods - default
	//////////////////////////////////////////////////////////////////////////////////////////////////////


	//////////////////////////////////////////////////////////////////////////////////////////////////////
	///h : init content
	/**
	 * init content
	 * initializing content and potential html rendering all requests
	 * to other uris.
	 *
	 * @access public
	 * @param
	 * @return vault
	 *
	 */
	dc7__behavior__packery.f_init = function()
		{
		dc7__behavior__packery.f_initData();
		dc7__behavior__packery.f_initContent();
		}

	//////////////////////////////////////////////////////////////////////////////////////////////////////
	///h : init data
	/**
	 * init content
	 * initializing content and potential html rendering all requests
	 * to other uris.
	 *
	 * @access public
	 * @param
	 * @return vault
	 *
	 */
	dc7__behavior__packery.f_initData = function($o_config)
		{
		dc7__behavior__packery.__o_data = {};
		dc7__behavior__packery.__o_data['o_config'] = $o_config;
		}

	//////////////////////////////////////////////////////////////////////////////////////////////////////
	///h : init data
	/**
	 * init content
	 * initializing content and potential html rendering all requests
	 * to other uris.
	 *
	 * @access public
	 * @param
	 * @return vault
	 *
	 */
	dc7__behavior__packery.f_initContent = function()
		{
		
		///h : self init
		dc7__behavior.f_initBehaviorClass({'s_behavior_id':'packery', 'class_behavior':dc7__behavior__packery});
		dc7__behavior.f_updateElements();
		}


	//////////////////////////////////////////////////////////////////////////////////////////////////////
	///h : class dynamic methods - default
	//////////////////////////////////////////////////////////////////////////////////////////////////////

	//////////////////////////////////////////////////////////////////////////////////////////////////////
	///h : init content
	/**
	 * init content
	 * initializing content and potential html rendering all requests
	 * to other uris.
	 *
	 * @access public
	 * @param
	 * @return vault
	 *
	 */
	dc7__behavior__packery.prototype.f_init = function($o_config)
		{
		///h :
		this.f_initData($o_config);
		
		///h :
		this.f_initContent();
		}
		
	//////////////////////////////////////////////////////////////////////////////////////////////////////
	///h : init content
	/**
	 * init content
	 * initializing content and potential html rendering all requests
	 * to other uris.
	 *
	 * @access public
	 * @param
	 * @return vault
	 *
	 */
	dc7__behavior__packery.prototype.f_initData = function($o_config)
		{
		this.__o_data = {};
		this.__o_data['jqo_element'] = $o_config['jqo_element'];
		this.__o_data['jqo_element'] = (($o_config['s_element_selector'] !== undefined) ? this.__o_data['jqo_element'].find($o_config['s_element_selector']).first() : this.__o_data['jqo_element']);
		this.__o_data['s_gutter'] = (($o_config['s_gutter'] === undefined) ? 0 : $o_config['s_gutter']);
		this.__o_data['s_transition_duration'] = (($o_config['s_transition_duration'] === undefined) ? '0s' : $o_config['s_transition_duration']);
		this.__o_data['s_item_selector'] = (($o_config['s_item_selector'] === undefined) ? '.dc7__behavior__packery_item' : $o_config['s_item_selector']);
		this.__o_data['b_init'] = false; 
		}

	//////////////////////////////////////////////////////////////////////////////////////////////////////
	///h : init content
	/**
	 * init content
	 * initializing content and potential html rendering all requests
	 * to other uris.
	 *
	 * @access public
	 * @param
	 * @return vault 
	 *
	 */
	dc7__behavior__packery.prototype.f_initContent = function($o_config)
		{
		///h : 
		if(this.__o_data['s_item_selector'] === '.dc7__behavior__packery_item')
			{ 
			this.__o_data['jqo_element'].children().addClass('dc7__behavior__packery_item');
			}
		this.__o_data['jqo_childs'] = this.__o_data['jqo_element'].find('.dc7__behavior__packery_item');
		this.__o_data['i_childs'] = this.__o_data['jqo_childs'].length;
	
		///h : event - load - update
		dc7.__o_data['dom__jqo_window'].bind('load', {'o_base':this}, function($o_event){$o_event.data['o_base'].f_update();});

		///h : event - load - update
		dc7.__o_data['dom__jqo_window'].bind('dc7__events__updateview resize', {'o_base':this}, function($o_event){$o_event.data['o_base'].f_update();});

		///h : direct - update
		this.f_update();
		}
	
	//////////////////////////////////////////////////////////////////////////////////////////////////////
	///h : class dynamic methods - default
	//////////////////////////////////////////////////////////////////////////////////////////////////////

	//////////////////////////////////////////////////////////////////////////////////////////////////////
	///h : init content
	/**
	 * init content
	 * initializing content and potential html rendering all requests
	 * to other uris.
	 *
	 * @access public
	 * @param
	 * @return vault
	 *
	 */
	dc7__behavior__packery.prototype.f_update = function()
		{
		///h :
		if(this.__o_data['b_init'] === false)
			{
			///h :
			this.__o_data['b_init'] = true;

			///h :
			this.__o_data['jqo_element'].packery({'gutter':this.__o_data['s_gutter'], 'transitionDuration':'0.001s', 'itemSelector':'.dc7__behavior__packery_item', 'percentPosition':true, 'resize':true, 'initLayout':false, 'percentPosition':true});	
			this.__o_data['jqo_element'].packery();
	//		jQuery(window).trigger('resize');
			}
		this.__o_data['jqo_element'].packery();
		}


//////////////////////////////////////////////////////////////////////////////////////////////////////////////
///h : direct call
//////////////////////////////////////////////////////////////////////////////////////////////////////////////

///h : init system
jQuery(document).bind('ready', {}, function(){dc7__behavior__packery.f_initSystem();});


//////////////////////////////////////////////////////////////////////////////////////////////////////////////
///h : description
//////////////////////////////////////////////////////////////////////////////////////////////////////////////
