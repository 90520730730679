/*
//////////////////////////////////////////////////////////////////////////////////////////////////////////////
code:		plustic — massimo cardascia — www.plustic.de
code language:	js 5
code style:	whitesmiths style variant - 8 spaces tab - http://en.wikipedia.org/wiki/indent_style
//////////////////////////////////////////////////////////////////////////////////////////////////////////////
*/

///*	class definition /////////////////////////////////////////////////////////////////////////////////////
function om3__behavior__slider($o_config){this.f_construct($o_config);}

///*    class properties /////////////////////////////////////////////////////////////////////////////////////

///*    class system init ////////////////////////////////////////////////////////////////////////////////////
	om3__behavior__slider.f_initSystem = function()
		{
		om3__behavior__slider.f_init();
		}

///*    class constructor ////////////////////////////////////////////////////////////////////////////////////
	om3__behavior__slider.prototype.f_construct = function($o_config)
		{
		///h : init
		this.f_init($o_config);
		}

///*    public static functions //////////////////////////////////////////////////////////////////////////////

///*    public functions /////////////////////////////////////////////////////////////////////////////////////

///*    pivate static functions //////////////////////////////////////////////////////////////////////////////

	//////////////////////////////////////////////////////////////////////////////////////////////////////
	///h : class static methods - default
	//////////////////////////////////////////////////////////////////////////////////////////////////////

	//////////////////////////////////////////////////////////////////////////////////////////////////////
	///h : init content
	/**
	 * init content
	 * initializing content and potential html rendering all requests
	 * to other uris.
	 *
	 * @access public
	 * @param
	 * @return vault
	 *
	 */
	om3__behavior__slider.f_init = function()
		{
		///h :
		om3__behavior__slider.f_initData();
		
		///h :
		om3__behavior__slider.f_initContent();
		}

	//////////////////////////////////////////////////////////////////////////////////////////////////////
	///h : init data
	/**
	 * init content
	 * initializing content and potential html rendering all requests
	 * to other uris.
	 *
	 * @access public
	 * @param
	 * @return vault
	 *
	 */
	om3__behavior__slider.f_initData = function()
		{
		///h :
		om3__behavior__slider.__o_data = {};
		
		///h :
		om3__behavior__slider.__o_data['admin__b_available'] = jQuery('body').hasClass('neos-backend');
		}


	//////////////////////////////////////////////////////////////////////////////////////////////////////
	///h : init data
	/**
	 * init content
	 * initializing content and potential html rendering all requests
	 * to other uris.
	 *
	 * @access public
	 * @param
	 * @return vault
	 *
	 */
	om3__behavior__slider.f_initContent = function()
		{
		///h :
		if(om3__behavior__slider.__o_data['admin__b_available'] === true)
			{
			return;
			}

		///h : init behavior
		dc7__behavior.f_initBehaviorClass({'s_behavior_id':'slider', 'class_behavior':om3__behavior__slider, 'selector_behavior':'.om3__segment__slider', 's_dom_config':'om3__behavior__slider'});
		dc7__behavior.f_updateElements();
		}


	//////////////////////////////////////////////////////////////////////////////////////////////////////
	///h : init data
	/**
	 * init content
	 * initializing content and potential html rendering all requests
	 * to other uris.
	 *
	 * @access public
	 * @param
	 * @return vault
	 *
	 */
	om3__behavior__slider.prototype.f_init = function($o_config)
		{
		///h : update important values
		this.f_initData($o_config); 

		///h : init engine
		this.f_initContent(); 
		}


	//////////////////////////////////////////////////////////////////////////////////////////////////////
	///h : init data
	/**
	 * init content
	 * initializing content and potential html rendering all requests
	 * to other uris.
	 *
	 * @access public
	 * @param
	 * @return vault
	 *
	 */
	om3__behavior__slider.prototype.f_initData = function($o_config)
		{
		///h : data 
		this.__o_data = {};
		this.__o_data['o_config'] = $o_config;
		this.__o_data['jqo_element'] = $o_config['jqo_element'];
		this.__o_data['jqo_slides'] = this.__o_data['jqo_element'].find('.om3__segment__slider_slide');
		this.__o_data['i_slides'] = this.__o_data['jqo_slides'].length;
		this.__o_data['i_slidesonscreen'] = this.__o_data['jqo_element'].data('slidesonscreen');
		}
		
	
	//////////////////////////////////////////////////////////////////////////////////////////////////////
	///h : init data
	/**
	 * init content
	 * initializing content and potential html rendering all requests
	 * to other uris.
	 *
	 * @access public
	 * @param
	 * @return vault
	 *
	 */
	om3__behavior__slider.prototype.f_initContent = function()
		{
		///h : deactivate in frontend
		if(om3__behavior__slider.__o_data['admin__b_available'] === true)
			{
			return;
			} 

		///h : 
		this.__o_data['jqo_element'].addClass('owl-carousel');

		///h : init owl slider
		$o_config = {};
		$o_config['items'] = this.__o_data['i_slidesonscreen'];
		
		$o_config['loop'] = ((this.__o_data['i_slides'] < 2) ? false : true);
		$o_config['nav'] = true;
		$o_config['dots'] = true;
		$o_config['autoplay'] = true;
		$o_config['autoplayTimeout'] = 6000;
		$o_config['autoplaySpeed'] = 1000;
		$o_config['responsive'] = {0:{'items':1}, 600:{'items':this.__o_data['i_slidesonscreen']}};

		///h : 
		this.__o_data['jqo_element'].owlCarousel($o_config);
		this.__o_data['o_owl'] = this.__o_data['jqo_element'].data('owlCarousel');

		///h :	
		this.__o_data['jqo_element'].find('.owl-prev').html('<i class="fa fa-chevron-circle-left" aria-hidden="true"></i>');
		this.__o_data['jqo_element'].find('.owl-next').html('<i class="fa fa-chevron-circle-right" aria-hidden="true"></i>');
		}

///*    init class ///////////////////////////////////////////////////////////////////////////////////////////
jQuery(document).bind('ready', function(){om3__behavior__slider.f_initSystem();});
