//////////////////////////////////////////////////////////////////////////////////////////////////////////////
///h : code:		plustic — massimo cardascia — www.plustic.de
///h : code language:	php 5.6
///h : code style:	whitesmiths style variant - 8 spaces tab - http://en.wikipedia.org/wiki/indent_style
//////////////////////////////////////////////////////////////////////////////////////////////////////////////


//////////////////////////////////////////////////////////////////////////////////////////////////////////////
///h : paths
//////////////////////////////////////////////////////////////////////////////////////////////////////////////


//////////////////////////////////////////////////////////////////////////////////////////////////////////////
///h : imports
//////////////////////////////////////////////////////////////////////////////////////////////////////////////


//////////////////////////////////////////////////////////////////////////////////////////////////////////////
///h : class definition
//////////////////////////////////////////////////////////////////////////////////////////////////////////////

///h : class
function dc7__behavior__pagescrollup($o_config){this.f_construct($o_config);}

	//////////////////////////////////////////////////////////////////////////////////////////////////////
	///h : class properites
	//////////////////////////////////////////////////////////////////////////////////////////////////////

	///h : class vars
	dc7__behavior__pagescrollup.__o_data = null;				///h : stores class data
	dc7__behavior__pagescrollup.__o_config = null;				///h : stores class config

	//////////////////////////////////////////////////////////////////////////////////////////////////////
	///h : class system init
	//////////////////////////////////////////////////////////////////////////////////////////////////////

	//////////////////////////////////////////////////////////////////////////////////////////////////////
	///h : init system
	/**
	 * init system
	 * initialising the system. all default mehtods to init data and content are
	 * called when the class is included.
	 * 
	 * @access public
	 * @param
	 * @return vault
	 */
	dc7__behavior__pagescrollup.f_initSystem = function()
		{
		dc7__behavior__pagescrollup.f_init();
		}
	

	/////////////////////////////////////////////////////////////////////////////////////////////////////
	///h : class system init
	//////////////////////////////////////////////////////////////////////////////////////////////////////

	//////////////////////////////////////////////////////////////////////////////////////////////////////
	///h : init system
	/**
	 * init system
	 * initialising the system. all default mehtods to init data and content are
	 * called when the class is included.
	 * 
	 * @access public
	 * @param
	 * @return vault
	 */
	dc7__behavior__pagescrollup.prototype.f_construct = function($o_config)
		{
		this.f_init($o_config);
		}


	//////////////////////////////////////////////////////////////////////////////////////////////////////
	///h : class static methods - default
	//////////////////////////////////////////////////////////////////////////////////////////////////////


	//////////////////////////////////////////////////////////////////////////////////////////////////////
	///h : init content
	/**
	 * init content
	 * initializing content and potential html rendering all requests
	 * to other uris.
	 *
	 * @access public
	 * @param
	 * @return vault
	 *
	 */
	dc7__behavior__pagescrollup.f_init = function()
		{
		dc7__behavior__pagescrollup.f_initData();
		dc7__behavior__pagescrollup.f_initContent();
		}


	//////////////////////////////////////////////////////////////////////////////////////////////////////
	///h : init data
	/**
	 * init content
	 * initializing content and potential html rendering all requests
	 * to other uris.
	 *
	 * @access public
	 * @param
	 * @return vault
	 *
	 */
	dc7__behavior__pagescrollup.f_initData = function()
		{
		///h :
		dc7__behavior__pagescrollup.__o_data = {};
		dc7__behavior__pagescrollup.__o_data['jqo_base'] = null; 
		dc7__behavior__pagescrollup.__o_data['b_visible'] = false; 
		}


	//////////////////////////////////////////////////////////////////////////////////////////////////////
	///h : init data
	/**
	 * init content
	 * initializing content and potential html rendering all requests
	 * to other uris.
	 *
	 * @access public
	 * @param
	 * @return vault
	 *
	 */
	dc7__behavior__pagescrollup.f_initContent = function() 
		{
		///h :  
		dc7__behavior__pagescrollup.__o_data['jqo_base'] = jQuery('.dc7__behavior__pagescrollup');
		if(dc7__behavior__pagescrollup.__o_data['jqo_base'].length === 0) 
			{
			//dc7__behavior__pagescrollup.__o_data['jqo_base'] = dc7.__o_data['dom__jqo_body'].append('<div class=\'dc7__behavior__pagescrollup\'><i class=\'fa fa-chevron-circle-up\' aria-hidden=\'true\'></i></div>');
			dc7__behavior__pagescrollup.__o_data['jqo_base'] = dc7.__o_data['dom__jqo_body'].append('<a class=\'arrow-pagescroll-up __layout__icon\' href="#content"><img src=\'https://www.belsana-apotheken.de/_Resources/Static/Packages/permanent.belsana/BMP/icons/up_blue.png\'/></a>');
			}
		///h :
		dc7__behavior__pagescrollup.__o_data['jqo_base'] = jQuery('.dc7__behavior__pagescrollup');
		TweenMax.set(dc7__behavior__pagescrollup.__o_data['jqo_base'], {'autoAlpha':0});

		///h :  
		dc7__behavior__pagescrollup.__o_data['jqo_base'].bind('click', {}, function(){dc7__scroller.f_scroll({'i_y':0, 'num_duration':0.8});});

		////h : 
		dc7.__o_data['dom__jqo_window'].bind('scroll load', {}, function(){dc7__behavior__pagescrollup.f_update();});
		}


	//////////////////////////////////////////////////////////////////////////////////////////////////////
	///h : init content
	/**
	 * init content
	 * initializing content and potential html rendering all requests
	 * to other uris.
	 *
	 * @access public
	 * @param
	 * @return vault
	 *
	 */
	dc7__behavior__pagescrollup.f_update = function()
		{
		///h :
		var $num_opacity = -1;		///h :
		var $b_visible = false;		///h :

		///h : check
		$b_visible = (( window.pageYOffset < 1) ? false : true);
		if(dc7__behavior__pagescrollup.__o_data['b_visible'] !== $b_visible)
			{
			///h :
			dc7__behavior__pagescrollup.__o_data['b_visible'] = $b_visible;

			///h :
			$num_opacity = (($b_visible === true) ? 1 : 0);
			TweenMax.to(dc7__behavior__pagescrollup.__o_data['jqo_base'], 0.4, {'ease':Expo.easeOut, 'autoAlpha':$num_opacity});

			///h :
			dc7__dom.f_setClassVar(dc7__behavior__pagescrollup.__o_data['jqo_base'], '__b_visible', $b_visible);
			}
		}

	//////////////////////////////////////////////////////////////////////////////////////////////////////
	///h : class methods - default
	//////////////////////////////////////////////////////////////////////////////////////////////////////


	//////////////////////////////////////////////////////////////////////////////////////////////////////
	///h : init content
	/**
	 * init content
	 * initializing content and potential html rendering all requests
	 * to other uris.
	 *
	 * @access public
	 * @param
	 * @return vault
	 *
	 */
	dc7__behavior__pagescrollup.prototype.f_init = function($o_config)
		{
	
		}

	//////////////////////////////////////////////////////////////////////////////////////////////////////
	///h : init content
	/**
	 * init content
	 * initializing content and potential html rendering all requests
	 * to other uris.
	 *
	 * @access public
	 * @param
	 * @return vault
	 *
	 */
	dc7__behavior__pagescrollup.prototype.f_initData = function($o_config)
		{
		this.__o_data = {};
		this.__o_data['jqo_element'] = $o_config['jqo_element'];
		}

	//////////////////////////////////////////////////////////////////////////////////////////////////////
	///h : init content
	/**
	 * init content
	 * initializing content and potential html rendering all requests
	 * to other uris.
	 *
	 * @access public
	 * @param
	 * @return vault
	 *
	 */
	dc7__behavior__pagescrollup.prototype.f_initContent = function($o_config)
		{
		}
	
	

//////////////////////////////////////////////////////////////////////////////////////////////////////////////
///h : direct call
//////////////////////////////////////////////////////////////////////////////////////////////////////////////

///h : init system
jQuery(document).bind('ready', {}, function(){dc7__behavior__pagescrollup.f_initSystem();});


//////////////////////////////////////////////////////////////////////////////////////////////////////////////
///h : description
//////////////////////////////////////////////////////////////////////////////////////////////////////////////
