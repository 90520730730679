setTimeout(function () {
    window.dispatchEvent(new Event('resize'));
},2000);

setTimeout(function () {
    window.dispatchEvent(new Event('resize'));
},5000);

setTimeout(function () {
    window.dispatchEvent(new Event('resize'));
},10000);

/* detect firefox and add body class to hide in page anchor links in Firefox ( do not work in BOB iframes  )*/

(function(){
    var isFirefox = navigator.userAgent.toLowerCase().indexOf('firefox') > -1;
    var isSafari = navigator.userAgent.indexOf('Safari') != -1 && navigator.userAgent.indexOf('Chrome') == -1;
    if(isFirefox) document.body.classList.add('firefox');
    if(isSafari) document.body.classList.add('safari');
})();

function getHostFromUrl() {
    var hostName = window.location.protocol + "//" + window.location.hostname;
    if (window.location.port > 443) {
        hostName += ':' + window.location.port;
    }
    // Andy: hotfix , hardcode server until we have a better solution for the iconm paths
    hostName = 'https://neos.cluster.belsana.de';
    return hostName;
}

(function scrollToIdFirefoxFix(){
    if (true) {
   // if (navigator.userAgent.indexOf("Firefox") != -1) {
        var targetElem;
        if(location.hash && location.hash.length>1){
            targetElem = document.querySelector(location.hash);
        }
        if(targetElem){
            setTimeout(function(){
                targetElem.scrollIntoView({
                    behavior : "smooth"
                });
            },3000);
        }

    }
})();